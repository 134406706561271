
import api from "@/base/utils/request";

// 获取分类列表
export const categoryList = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/index",
    method: "post",
    data
  });
};

// 保存新增或编辑分类
export const saveCategory = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/save",
    method: "post",
    data
  });
};

// 更新显示状态
export const updateShow = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/updateStatus",
    method: "post",
    data
  });
};



// 转移分类
export const transferCategory = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/move",
    method: "post",
    data
  });
};

// 软删除
export const softDelete = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/softDelete",
    method: "post",
    data
  });
};

// 还原
export const putBack = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/putBack",
    method: "post",
    data
  });
};

// 真删除
export const del = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/del",
    method: "post",
    data
  });
};

// 分类下拉选项
export const categoryOptions = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/all",
    method: "post",
    data
  });
};
// 列表排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc_activity/activity_category/updateSort",
    method: "post",
    data
  });
};