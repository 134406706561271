<template>
  <el-dialog
    title="数据转移"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('CategoryName')"
  >
    <el-form
      size="medium"
      :model="CategoryData"
      :rules="rules"
      ref="CategoryName"
      label-width="95px"
      @submit.native.prevent
    >
      <el-form-item label="转出分类：" prop="name">
        <el-input
          type="text"
          style="width: 80%"
          v-model="CategoryData.name"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="选择分类：" prop="target_id">
        <el-select
          v-model="CategoryData.target_id"
          :placeholder="optionsLoading ? '分类加载中...' : '请选择分类'"
          style="width: 80%"
        >
          <el-option
            v-for="item in selectArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('CategoryName')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { transferCategory, categoryOptions } from "../../api/cyc-category-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      optionsLoading: false,
      categoryOptions: [],
      rules: {
        target_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getCategoryOptions();
      }
    },
  },
  methods: {
    // 获取分类数据选项
    getCategoryOptions() {
      this.optionsLoading = true;
      categoryOptions()
        .then((res) => {
          this.categoryOptions = res.data;
          this.optionsLoading = false;
        })
        .catch((err) => {
          this.optionsLoading = false;
        });
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {};
          data.id = this.CategoryData.id;
          data.target_id = this.CategoryData.target_id;
          transferCategory(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    CategoryData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
    selectArr() {
      if (this.categoryOptions) {
        return this.categoryOptions.filter(
          (item) => item.id !== this.CategoryData.id
        );
      }
    },
  },
};
</script>

<style></style>
